import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ShieldShaded } from 'react-bootstrap-icons';
import BuyButton from './BuyButton';
import { getUserInfo } from './api';

const PricingPage = () => {
  const { t, i18n } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const verifyUser = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setIsLoading(false);
        return;
      }
      try {
        const info = await getUserInfo(token);
        setUserInfo(info);
      } catch (error) {
        console.error('Failed to verify user:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
      } finally {
        setIsLoading(false);
      }
    };
    verifyUser();
  }, []);

  const handleBuy = (stripeLink) => {
    if (!userInfo) {
      navigate('/login');
    } else {
      const email = encodeURIComponent(userInfo.email);
      const linkWithEmail = `${stripeLink}?prefilled_email=${email}`;
      window.location.href = linkWithEmail;
    }
  };


  const plans = [
    {
      name: "Free",
      price: "$0.00",
      details: [
        "Credits: 10",
        "Usage: 10 times ",
        "Can be used by 1 people"
      ],
      stripeLink: ""
    },
    {
      name: "Basic",
      price: "$9.9",
      details: [
        "Credits: 25",
        "Usage: 25 times",
        "About $0.3 per use",
        "Can be used by 1 person"
      ],
      stripeLink: "https://buy.stripe.com/bIYbMbbiwfWx1JCbIY"
    },
    {
      name: "Standard",
      price: "$19.9",
      details: [
        "Credits: 75",
        "Usage: 75 times",
        "About $0.2 per use",
        "Can be used by 1 person"
      ],
      stripeLink: "https://buy.stripe.com/dR68zZcmAeSt3RKcN3"
    },
    {
      name: "Pro",
      price: "$99.9",
      details: [
        "Credits: 500",
        "Usage: 500 times",
        "About $0.2 per use",
        "Can be used by 5 people"
      ],
      stripeLink: "https://buy.stripe.com/5kAg2r9ao8u52NG3cu"
    }
  ];
  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-teal-50 to-blue-50 flex items-center justify-center">
        <div className="animate-pulse text-gray-500">{t('pricing.loading')}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-teal-50 to-blue-50">
      {/* 价格方案 */}
      <div className="max-w-7xl mx-auto px-6 py-12">
        <h1 className="text-4xl font-bold text-center mb-12">
          <span className="bg-gradient-to-r from-teal-500 to-blue-400 bg-clip-text text-transparent">
            {t('pricing.titleHighlight')}
          </span>
          <br />
          {t('pricing.title')}
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {plans.map((plan, index) => (
            <div 
              key={index}
              className="bg-white/90 backdrop-blur-sm rounded-2xl p-8 shadow-xl border border-gray-100/50 relative transition-all hover:transform hover:-translate-y-2"
            >
              {plan.name !== "Free" && (
                <div className="absolute top-0 right-0 bg-gradient-to-r from-rose-400 to-pink-500 text-white px-3 py-1 rounded-tr-2xl rounded-bl-lg text-sm font-semibold">
                  {t('pricing.limitedOffer')}
                </div>
              )}
              
              <div className="space-y-6">
                <h2 className="text-2xl font-bold text-gray-800">{t(`pricing.plans.${plan.name.toLowerCase()}.name`)}</h2>
                
                <div className="bg-gradient-to-r from-teal-100 to-blue-100 p-4 rounded-xl">
                  <p className="text-3xl font-bold bg-gradient-to-r from-teal-600 to-blue-500 bg-clip-text text-transparent">
                    {plan.price}
                  </p>
                </div>

                <ul className="space-y-4">
                  {plan.details.map((detail, detailIndex) => (
                    <li key={detailIndex} className="flex items-start text-gray-600">
                      <svg 
                        className="w-5 h-5 mr-3 text-teal-500 flex-shrink-0 mt-1" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      <span>{t(`pricing.plans.${plan.name.toLowerCase()}.details.${detailIndex}`)}</span>
                    </li>
                  ))}
                </ul>

                <BuyButton 
                  plan={plan} 
                  userInfo={userInfo} 
                  handleBuy={handleBuy}
                  className="w-full mt-auto"
                />
              </div>
            </div>
          ))}
        </div>
      </div>


    </div>
  );
};

export default PricingPage;