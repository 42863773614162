import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { LightningChargeFill, ShieldShaded, PatchCheck } from 'react-bootstrap-icons';
import RegisterPage from './components/RegisterPage';
import VerifyEmailPage from './components/VerifyEmailPage';
import UserProfilePage from './components/UserProfilePage';
import PricingPage from './components/PricingPage';
import { getUserInfo } from './components/api';
import Toast from './components/Toast';
import Layout from './components/Layout';
import { HelmetProvider } from 'react-helmet-async';
import CoolPortfolio from './components/CoolPortfolio'
import Login from './components/Login';

const App = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [toast, setToast] = useState({ isOpen: false, message: '', type: 'success' });

  useEffect(() => {
    const fetchUserInfo = async () => {
      const token = localStorage.getItem('token');
      const storedUserInfo = localStorage.getItem('userInfo');

      if (token && storedUserInfo) {
        setUserInfo(JSON.parse(storedUserInfo));
        setIsLoading(false);
      } else if (token) {
        try {
          const info = await getUserInfo(token);
          setUserInfo(info);
          localStorage.setItem('userInfo', JSON.stringify(info));
        } catch (error) {
          console.error('Failed to fetch user info:', error);
          localStorage.removeItem('token');
          localStorage.removeItem('userInfo');
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    // Initial fetch
    fetchUserInfo();

    // Add event listener for refresh
    window.addEventListener('loginStateChange', fetchUserInfo);

    // Cleanup
    return () => {
      window.removeEventListener('loginStateChange', fetchUserInfo);
    };
  }, []);

  const showToast = (message, type = 'success') => {
    setToast({ isOpen: true, message, type });
  };

  const hideToast = () => {
    setToast({ ...toast, isOpen: false });
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-teal-50 to-blue-50 flex items-center justify-center">
        <div className="text-center space-y-4 animate-pulse">
          <ShieldShaded className="h-16 w-16 text-teal-500" />
          <p className="text-gray-600 font-medium">Securing Connection...</p>
        </div>
      </div>
    );
  }

  return (
    <HelmetProvider>
      <Router>
        <div>
          <Routes>
            {/* 独立路由 - 不使用Layout */}
            <Route path="/login" element={<Login />} />
            {/* 使用Layout的路由 */}
            <Route element={<Layout />}>
              <Route path="/" element={<CoolPortfolio userInfo={userInfo} />} />
              <Route path="/register" element={<RegisterPage showToast={showToast} />} />
              <Route path="/verify-email" element={<VerifyEmailPage showToast={showToast} />} />
              <Route
                path="/profile"
                element={
                  userInfo ? (
                    <UserProfilePage userInfo={userInfo} setUserInfo={setUserInfo} showToast={showToast} />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route path="/pricing" element={<PricingPage showToast={showToast} />} />
            </Route>
          </Routes>

          <Toast
            isOpen={toast.isOpen}
            message={toast.message}
            type={toast.type}
            onClose={hideToast}
            className="fixed bottom-8 right-8 min-w-[300px]"
          />
        </div>
      </Router>
    </HelmetProvider>
  );
};

export default App;