import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserInfo } from './api';
import Alert from './Alert';
import { ShieldShaded, ArrowClockwise } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

const UserProfilePage = () => {
  const { t, i18n } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [alert, setAlert] = useState({ message: '', type: 'error' });
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }
      try {
        const info = await getUserInfo(token);
        setUserInfo(info);
      } catch (error) {
        setAlert({ message: t('profile.fetchError'), type: 'error' });
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
        setTimeout(() => navigate('/login'), 2000);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserInfo();
  }, [navigate, t]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    navigate('/login');
  };

  const refreshUserInfo = async () => {
    const token = localStorage.getItem('token');
    try {
      const info = await getUserInfo(token);
      setUserInfo(info);
    } catch (error) {
      setAlert({ message: t('profile.refreshError'), type: 'error' });
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-teal-50 to-blue-50 flex items-center justify-center">
        <div className="animate-pulse text-gray-500">{t('profile.loading')}</div>
      </div>
    );
  }

  if (!userInfo) {
    return <div className="min-h-screen bg-gradient-to-br from-teal-50 to-blue-50">{t('profile.redirecting')}</div>;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-teal-50 to-blue-50">
      {/* 用户信息区域 */}
      <div className="max-w-3xl mx-auto p-6 mt-12">
        <div className="bg-white/90 backdrop-blur-sm rounded-2xl p-8 shadow-xl border border-gray-100/50">
          <div className="flex justify-between items-start mb-8">
            <div>
              <h2 className="text-3xl font-bold text-gray-800 mb-2">
                {t('profile.welcome')}
              </h2>
            </div>
            <button
              onClick={refreshUserInfo}
              className="bg-gray-100 hover:bg-gray-200 p-2 rounded-lg transition-colors"
              title={t('userInfo.refresh')}
            >
              <ArrowClockwise className="w-6 h-6 text-teal-500" />
            </button>
          </div>

          {alert.message && <Alert message={alert.message} type={alert.type} />}

          {/* 用户信息详情 */}
          <div className="bg-gray-50/50 rounded-xl p-6 mb-8 shadow-inner">
            <div className="grid grid-cols-2 gap-6">
              <div className="space-y-2">
                <p className="text-gray-500 font-medium">{t('userInfo.email')}</p>
                <p className="text-gray-800 font-mono break-all">{userInfo.email}</p>
              </div>
              
              <div className="space-y-2">
                <p className="text-gray-500 font-medium">{t('userInfo.credit')}</p>
                <div className="flex items-baseline space-x-2">
                  <span className="text-3xl font-bold text-teal-600">
                    {userInfo.left_credit}
                  </span>
                  <span className="text-gray-500">{t('userInfo.credits')}</span>
                </div>
              </div>
            </div>

            <div className="mt-6 bg-teal-50/50 p-4 rounded-lg border border-teal-100">
              <p className="text-sm text-gray-600">
                <span className="font-medium text-teal-600">
                  {t('userInfo.usageTip')}
                </span>{' '}
                {Math.floor(userInfo.left_credit / 2)} {t('userInfo.timesRemaining')}
              </p>
            </div>
          </div>

          <button
            onClick={handleLogout}
            className="w-full bg-gradient-to-r from-rose-400 to-pink-500 text-white py-3.5 rounded-xl font-semibold hover:shadow-lg transition-all"
          >
            {t('profile.logout')} →
          </button>
        </div>
      </div>

    </div>
  );
};

export default UserProfilePage;