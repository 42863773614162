import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Toast from './Toast';

const Layout = () => {
  const [toast, setToast] = useState({ isOpen: false, message: '', type: 'success' });

  const showToast = (message, type = 'success') => {
    setToast({ isOpen: true, message, type });
  };

  const hideToast = () => {
    setToast({ ...toast, isOpen: false });
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Main Content */}
      <main>
        <Outlet />
      </main>

      {/* Toast Notification */}
      <Toast
        isOpen={toast.isOpen}
        message={toast.message}
        type={toast.type}
        onClose={hideToast}
        className="fixed bottom-8 right-8 min-w-[300px]"
      />
    </div>
  );
};

export default Layout;