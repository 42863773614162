import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChevronLeft, ChevronRight, LockFill } from 'react-bootstrap-icons';
import UserDropdown from './UserDropdown';
import img1 from '../img/1.png';
import img2 from '../img/2.png';
import img3 from '../img/3.png';
import img4 from '../img/4.png';
import img5 from '../img/5.png';
import img6 from '../img/6.png';
import bg from '../img/bg.png';
import video1 from '../video/1.MP4';
import video2 from '../video/2.mp4';
import video3 from '../video/3.mp4';
import video4 from '../video/4.mp4';
const CoolPortfolio = ({ userInfo }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [activeTab, setActiveTab] = useState('all');
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  
  // 修改图片数据结构，添加限制级别
  const allImages = [
    {
      id: 1,
      src: img1,
      title: '03/15',
      level: 0,
      type: 'photo'
    },
    {
      id: 2,
      src: img2,
      title: '03/15',
      level: 0,
      type: 'photo'
    },
    {
      id: 3,
      src: img3,
      title: '03/10',
      level: 1,
      type: 'photo'
    },
    {
      id: 4,
      src: img4,
      title: '03/10',
      level: 1,
      type: 'photo'
    },
    {
      id: 5,
      src: img5,
      title: '03/05',
      level: 1,
      type: 'photo'
    },
    {
      id: 6,
      src: img6,
      title: '03/05',
      level: 1,
      type: 'photo'
    },
    {
      id: 7,
      src: video1,
      title: '03/05',
      level: 1,
      type: 'video'
    },
    {
      id: 8,
      src: video2,
      title: '03/05',
      level: 1,
      type: 'video'
    },
    {
      id: 9,
      src: video3,
      title: '03/05',
      level: 0,
      type: 'video'
    },
    {
      id: 10,
      src: video4,
      title: '03/05',
      level: 1,
      type: 'video'
    }
  ];

  useEffect(() => {
    const checkLoginStatus = () => {
      const token = localStorage.getItem('token');
      setIsLoggedIn(!!token);
      setIsCheckingAuth(false);
    };

    // Initial check
    checkLoginStatus();

    // Add event listeners
    window.addEventListener('storage', checkLoginStatus);
    window.addEventListener('loginStateChange', checkLoginStatus);

    // Cleanup
    return () => {
      window.removeEventListener('storage', checkLoginStatus);
      window.removeEventListener('loginStateChange', checkLoginStatus);
    };
  }, []);

  const handlePrevImage = (e) => {
    e.stopPropagation();
    const currentIndex = allImages.findIndex(img => img.src === selectedImage);
    // 向前查找下一个有权限查看的图片
    for (let i = currentIndex - 1; i >= 0; i--) {
      if (canViewImage(allImages[i].level)) {
        setSelectedImage(allImages[i].src);
        return;
      }
    }
  };

  const handleNextImage = (e) => {
    e.stopPropagation();
    const currentIndex = allImages.findIndex(img => img.src === selectedImage);
    // 向后查找下一个有权限查看的图片
    for (let i = currentIndex + 1; i < allImages.length; i++) {
      if (canViewImage(allImages[i].level)) {
        setSelectedImage(allImages[i].src);
        return;
      }
    }
  };

  // 检查用户是否有权限查看图片
  const canViewImage = (imageLevel) => {
    // level 0 的内容所有人都可以查看
    if (imageLevel === 0) return true;
    // 其他级别需要登录且会员等级足够
    if (!userInfo) return false;
    return userInfo.membership >= imageLevel;
  };

  const handleImageClick = (img) => {
    if (!canViewImage(img.level)) {
      setShowSubscriptionModal(true);
      return;
    }
    setSelectedImage(img.src);
  };

  // 过滤图片
  const filterImages = (images) => {
    return images.filter(img => {
      // 首先根据标签过滤
      if (activeTab !== 'all' && img.type !== activeTab) {
        return false;
      }
      return true; // 不再根据权限过滤，而是显示模糊效果
    });
  };

  const handleSubscribe = (plan) => {
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }
    window.open('https://buy.stripe.com/8wM17x3Q47q13RKbJ7', '_blank');
  };

  return (
    <div className="min-h-screen bg-white text-pink-600 font-mono overflow-hidden relative">
      {/* Background Image */}
      <div 
        className="fixed inset-0 z-0"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          opacity: 0.55
        }}
      ></div>

      {/* Login/User Dropdown Button */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="fixed top-4 right-4 z-50"
      >
        <AnimatePresence mode="wait">
          {isCheckingAuth ? (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="px-6 py-2 bg-pink-600 text-white font-bold rounded-lg 
                       border-2 border-pink-400"
            >
              Loading...
            </motion.div>
          ) : isLoggedIn ? (
            <motion.div
              key="user-dropdown"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <UserDropdown userData={userInfo} />
            </motion.div>
          ) : (
            <motion.div
              key="login-button"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="px-6 py-2 bg-pink-600 text-white font-bold rounded-lg 
                       border-2 border-pink-400 cursor-pointer hover:bg-pink-500 
                       transition-colors duration-300"
              onClick={() => navigate('/login')}
            >
              Login
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>

      {/* Background Effects */}
      <div className="absolute inset-0 bg-white opacity-30 pointer-events-none z-0 animate-pulse"></div>
      <div className="fixed inset-0 pointer-events-none opacity-20">
        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-pink-200 to-transparent opacity-20"></div>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-pink-200 to-transparent opacity-20"></div>
      </div>

      {/* Content Container */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="relative z-10 container mx-auto px-4 py-16"
      >
        {/* Header */}
        <motion.h1 
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="text-6xl font-bold text-center mb-12 text-pink-600 
          relative overflow-hidden after:absolute after:inset-0 
          after:content-[attr(data-text)] after:text-pink-600 
          after:opacity-50 after:animate-glitch"
          data-text="Olivia Cookie"
        >
          Olivia Cookie
        </motion.h1>

        {/* Personal Introduction */}
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 0.65 }}
          transition={{ duration: 0.55 }}
          className="max-w-3xl mx-auto text-center mb-16 bg-white bg-opacity-90 p-8 rounded-xl border border-pink-400 shadow-lg backdrop-blur-sm"
        >
          <h2 className="text-2xl font-bold text-pink-600 mb-6">Hey there! I'm Olivia</h2>
          <p className="text-lg leading-relaxed text-pink-700 mb-6">
            I am a 21-year-old. Welcome to my channel, where I share my exclusive nude and sexy photos and videos!
          </p>
          
          <div className="space-y-6 text-left">
            {/* <div className="bg-pink-50 bg-opacity-50 p-4 rounded-lg">
              <h3 className="text-xl font-bold text-pink-600 mb-3">How to Join?</h3>
              <p className="text-pink-700">
                Easy! Hit that subscribe button and dive into my world anytime, anywhere.
              </p>
            </div>

            <p className="text-lg font-bold text-pink-600 italic">
              Don't miss out—subscribe now and let's explore beauty together. I'm waiting for you!
            </p> */}

            <motion.button
              onClick={() => handleSubscribe('pro')}
              className="w-full py-4 bg-pink-600 text-white font-bold rounded-lg 
                       border-2 border-pink-400 shadow-lg hover:shadow-xl
                       transition-all duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              animate={{
                scale: [1, 0.8, 1.5],
                boxShadow: [
                  "0 4px 6px -1px rgba(236, 72, 153, 0.1), 0 2px 4px -1px rgba(236, 72, 153, 0.06)",
                  "0 10px 15px -3px rgba(236, 72, 153, 0.1), 0 4px 6px -2px rgba(236, 72, 153, 0.05)",
                  "0 4px 6px -1px rgba(236, 72, 153, 0.1), 0 2px 4px -1px rgba(236, 72, 153, 0.06)"
                ]
              }}
              transition={{
                duration: 0.5,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            >
              Unlock My Nude Photo and Video🔒💦
            </motion.button>
          </div>
        </motion.div>

        {/* Tabs */}
        <div className="flex justify-center space-x-4 mb-8">
          <button
            onClick={() => setActiveTab('all')}
            className={`px-6 py-2 rounded-lg border-2 transition-colors duration-300 ${
              activeTab === 'all'
                ? 'bg-pink-600 text-white border-pink-400'
                : 'border-pink-600 text-pink-600 hover:bg-pink-600 hover:text-white'
            }`}
          >
            All
          </button>
          <button
            onClick={() => setActiveTab('photo')}
            className={`px-6 py-2 rounded-lg border-2 transition-colors duration-300 ${
              activeTab === 'photo'
                ? 'bg-pink-600 text-white border-pink-400'
                : 'border-pink-600 text-pink-600 hover:bg-pink-600 hover:text-white'
            }`}
          >
            Photo
          </button>
          <button
            onClick={() => setActiveTab('video')}
            className={`px-6 py-2 rounded-lg border-2 transition-colors duration-300 ${
              activeTab === 'video'
                ? 'bg-pink-600 text-white border-pink-400'
                : 'border-pink-600 text-pink-600 hover:bg-pink-600 hover:text-white'
            }`}
          >
            Video
          </button>
        </div>

        {/* Image Gallery */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="grid grid-cols-2 md:grid-cols-3 gap-6"
        >
          {filterImages(allImages).map((img, index) => (
            <motion.div
              key={img.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="relative overflow-hidden rounded-xl cursor-pointer shadow-lg bg-white bg-opacity-90 backdrop-blur-sm"
              onClick={() => handleImageClick(img)}
            >
              <div className="absolute inset-0 bg-pink-500 opacity-20 hover:opacity-0 transition-opacity"></div>
              {img.type === 'video' ? (
                <video 
                  src={img.src} 
                  className={`w-full h-64 object-cover transform transition-transform ${
                    !canViewImage(img.level) ? 'blur-[2px]' : ''
                  }`}
                  controls
                  preload="metadata"
                />
              ) : (
                <img 
                  src={img.src} 
                  alt={img.title} 
                  className={`w-full h-64 object-cover transform transition-transform ${
                    !canViewImage(img.level) ? 'blur-[2px]' : ''
                  }`}
                />
              )}
              <div className="absolute bottom-0 left-0 right-0 bg-white bg-opacity-50 p-2">
                <p className="text-pink-600 text-sm">{img.title}</p>
              </div>
              {!canViewImage(img.level) && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <LockFill className="w-12 h-12 text-pink-600" />
                </div>
              )}
            </motion.div>
          ))}
        </motion.div>

        {/* Subscription Modal */}
        <AnimatePresence>
          {showSubscriptionModal && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 z-50 bg-white bg-opacity-90 flex items-center justify-center p-8"
              onClick={() => setShowSubscriptionModal(false)}
            >
              <motion.div
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.8, opacity: 0 }}
                className="bg-white rounded-xl p-8 max-w-md w-full border-2 border-pink-400 shadow-xl"
                onClick={e => e.stopPropagation()}
              >
                <h2 className="text-2xl font-bold text-pink-600 mb-6 text-center">
                  Subscribe to Unlock
                </h2>
                <div className="space-y-4">
                  <button
                    onClick={() => handleSubscribe('pro')}
                    className="w-full py-3 bg-pink-600 text-white font-bold rounded-lg 
                             hover:bg-pink-500 transition-colors duration-300
                             border-2 border-pink-400 hover:border-pink-300"
                  >
                    My Hot Content - 7 Days Free Trial
                  </button>
                  <p className="text-sm text-pink-600 text-center mt-2">
                    Cancel anytime
                  </p>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Full Screen Image Modal */}
        <AnimatePresence>
          {selectedImage && (
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 z-50 bg-white bg-opacity-90 flex items-center justify-center p-8"
              onClick={() => setSelectedImage(null)}
            >
              {/* Previous Button */}
              {allImages.findIndex(img => img.src === selectedImage) > 0 && (
                <motion.button
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  className="absolute left-4 top-1/2 transform -translate-y-1/2 
                           bg-pink-600 text-white p-3 rounded-full
                           hover:bg-pink-500 transition-colors duration-300
                           border-2 border-pink-400 hover:border-pink-300"
                  onClick={handlePrevImage}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <ChevronLeft className="w-6 h-6" />
                </motion.button>
              )}

              {/* Content */}
              {allImages.find(img => img.src === selectedImage)?.type === 'video' ? (
                <motion.video
                  initial={{ scale: 0.8 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0.8 }}
                  src={selectedImage}
                  controls
                  className="max-w-full max-h-full object-contain"
                  onClick={(e) => e.stopPropagation()}
                />
              ) : (
                <motion.img
                  initial={{ scale: 0.8 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0.8 }}
                  src={selectedImage}
                  alt="Full Screen"
                  className="max-w-full max-h-full object-contain"
                  onClick={(e) => e.stopPropagation()}
                />
              )}

              {/* Next Button */}
              {allImages.findIndex(img => img.src === selectedImage) < allImages.length - 1 && (
                <motion.button
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 20 }}
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 
                           bg-pink-600 text-white p-3 rounded-full
                           hover:bg-pink-500 transition-colors duration-300
                           border-2 border-pink-400 hover:border-pink-300"
                  onClick={handleNextImage}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <ChevronRight className="w-6 h-6" />
                </motion.button>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>

      {/* Floating Tech Elements */}
      <div className="fixed top-0 left-0 w-full h-full pointer-events-none">
        <div className="absolute top-12 left-12 w-3 h-3 bg-pink-500 rounded-full animate-ping"></div>
        <div className="absolute bottom-24 right-24 w-2 h-2 bg-pink-400 rounded-full animate-pulse"></div>
      </div>
    </div>
  );
};

export default CoolPortfolio;