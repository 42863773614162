import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import bg from '../img/bg.png';

const Login = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const formDataObj = new FormData();
      formDataObj.append('username', formData.username);
      formDataObj.append('password', formData.password);

      const response = await axios.post('https://oliviacookie-dd75b8b9ac68.herokuapp.com/token', formDataObj);
      
      // Store the token in localStorage
      localStorage.setItem('token', response.data.access_token);
      localStorage.setItem('tokenType', response.data.token_type);

      // Fetch user info immediately after login
      const userInfoResponse = await axios.get('https://oliviacookie-dd75b8b9ac68.herokuapp.com/users/me/', {
        headers: {
          'Authorization': `Bearer ${response.data.access_token}`
        }
      });
      
      // Store user info
      localStorage.setItem('userInfo', JSON.stringify(userInfoResponse.data));
      
      // Dispatch custom event for login state change
      window.dispatchEvent(new Event('loginStateChange'));
      
      // Redirect to home page
      navigate('/');
    } catch (error) {
      setError(error.response?.data?.detail || 'Login failed');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-cover bg-center bg-no-repeat text-gray-700 font-mono overflow-hidden flex items-center justify-center"
         style={{ backgroundImage: `url(${bg})` }}>
      {/* Background Overlay */}
      <div className="absolute inset-0 bg-white/30 backdrop-blur-sm"></div>
      
      {/* Animated Grid Lines */}
      <div className="fixed inset-0 pointer-events-none opacity-10">
        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-pink-100 to-transparent opacity-20"></div>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-pink-100 to-transparent opacity-20"></div>
      </div>

      {/* Logo */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="fixed top-4 left-4 z-50"
      >
        <button
          onClick={() => navigate('/')}
          className="flex items-center space-x-2 text-2xl font-bold text-pink-600 hover:text-pink-500 transition-colors duration-300"
        >
          <span className="relative">
            Olivia Cookie
            <span className="absolute -inset-1 bg-pink-100 opacity-30 rounded-lg"></span>
          </span>
        </button>
      </motion.div>

      {/* Login Form */}
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="relative z-10 w-full max-w-md p-8 bg-white rounded-xl border border-pink-100 shadow-xl"
      >
        <h2 className="text-4xl font-bold text-center mb-8 text-pink-600">Login</h2>
        {error && (
          <div className="mb-4 p-3 bg-red-50 border border-red-100 text-red-600 rounded-lg">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-gray-700 mb-2">Email</label>
            <input
              type="email"
              name="username"
              value={formData.username}
              onChange={handleChange}
              className="w-full px-4 py-2 bg-white border border-pink-200 rounded-lg 
                       text-gray-700 focus:outline-none focus:border-pink-300
                       placeholder-gray-400"
              placeholder="Enter your email"
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2">Password</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full px-4 py-2 bg-white border border-pink-200 rounded-lg 
                       text-gray-700 focus:outline-none focus:border-pink-300
                       placeholder-gray-400"
              placeholder="Enter your password"
            />
          </div>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            type="submit"
            disabled={isLoading}
            className={`w-full py-3 bg-pink-600 text-white font-bold rounded-lg 
                     hover:bg-pink-500 transition-colors duration-300
                     border border-pink-500 hover:border-pink-400
                     disabled:opacity-50 disabled:cursor-not-allowed
                     flex items-center justify-center`}
          >
            {isLoading ? (
              <>
                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></div>
                Logging in...
              </>
            ) : (
              'Login'
            )}
          </motion.button>
          
          {/* Register Link */}
          <div className="mt-6 text-center">
            <p className="text-gray-700 mb-2">Don't have an account?</p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="button"
              onClick={() => navigate('/register')}
              className="w-full py-3 bg-white text-pink-600 font-bold rounded-lg 
                       hover:bg-pink-50 transition-colors duration-300
                       border border-pink-200 hover:border-pink-300"
            >
              Register Now
            </motion.button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default Login; 