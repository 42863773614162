import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { EyeFill, EyeSlashFill, ArrowLeft } from 'react-bootstrap-icons';
import axios from 'axios';
import bg from '../img/bg.png';

const RegisterPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post('https://oliviacookie-dd75b8b9ac68.herokuapp.com/register', {
        email: formData.email,
        password: formData.password,
        name: formData.name
      });

      // Store the token in localStorage
      localStorage.setItem('token', response.data.access_token);
      localStorage.setItem('tokenType', response.data.token_type);
      
      // Redirect to home page
      navigate('/');
    } catch (error) {
      setError(error.response?.data?.detail || 'Registration failed');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-cover bg-center bg-no-repeat text-gray-700 font-mono overflow-hidden flex items-center justify-center"
         style={{ backgroundImage: `url(${bg})` }}>
      {/* Background Overlay */}
      <div className="absolute inset-0 bg-white/30 backdrop-blur-sm"></div>
      
      {/* Logo */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="fixed top-4 left-4 z-50"
      >
        <button
          onClick={() => navigate('/')}
          className="flex items-center space-x-2 text-2xl font-bold text-pink-600 hover:text-pink-500 transition-colors duration-300"
        >
          <span className="relative">
            Olivia Cookie
            <span className="absolute -inset-1 bg-pink-100 opacity-30 rounded-lg"></span>
          </span>
        </button>
      </motion.div>

      {/* Register Form */}
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="relative z-10 w-full max-w-md p-8 bg-white rounded-xl border border-pink-100 shadow-xl"
      >
        {/* <button
          onClick={() => navigate('/')}
          className="mb-6 flex items-center text-gray-700 hover:text-pink-600 transition-colors"
        >
          <ArrowLeft className="mr-2" />
          Back to Home
        </button> */}

        <h2 className="text-4xl font-bold text-center mb-8 text-pink-600">Register</h2>
        {error && (
          <div className="mb-4 p-3 bg-red-50 border border-red-100 text-red-600 rounded-lg">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-gray-700 mb-2">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full px-4 py-2 bg-white border border-pink-200 rounded-lg 
                       text-gray-700 focus:outline-none focus:border-pink-300
                       placeholder-gray-400"
              placeholder="Enter your name"
              required
              disabled={isLoading}
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-4 py-2 bg-white border border-pink-200 rounded-lg 
                       text-gray-700 focus:outline-none focus:border-pink-300
                       placeholder-gray-400"
              placeholder="Enter your email"
              required
              disabled={isLoading}
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2">Password</label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="w-full px-4 py-2 bg-white border border-pink-200 rounded-lg 
                         text-gray-700 focus:outline-none focus:border-pink-300
                         placeholder-gray-400"
                placeholder="Enter your password"
                required
                disabled={isLoading}
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 
                         hover:text-pink-600 transition-colors duration-300"
              >
                {showPassword ? 
                  <EyeSlashFill className="w-5 h-5" /> : 
                  <EyeFill className="w-5 h-5" />
                }
              </button>
            </div>
          </div>
          <div>
            <label className="block text-gray-700 mb-2">Confirm Password</label>
            <div className="relative">
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                className="w-full px-4 py-2 bg-white border border-pink-200 rounded-lg 
                         text-gray-700 focus:outline-none focus:border-pink-300
                         placeholder-gray-400"
                placeholder="Confirm your password"
                required
                disabled={isLoading}
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 
                         hover:text-pink-600 transition-colors duration-300"
              >
                {showConfirmPassword ? 
                  <EyeSlashFill className="w-5 h-5" /> : 
                  <EyeFill className="w-5 h-5" />
                }
              </button>
            </div>
          </div>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            type="submit"
            disabled={isLoading}
            className={`w-full py-3 bg-pink-600 text-white font-bold rounded-lg 
                     hover:bg-pink-500 transition-colors duration-300
                     border border-pink-500 hover:border-pink-400
                     disabled:opacity-50 disabled:cursor-not-allowed
                     flex items-center justify-center`}
          >
            {isLoading ? (
              <>
                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></div>
                Registering...
              </>
            ) : (
              'Register'
            )}
          </motion.button>
          
          {/* Login Link */}
          <div className="mt-6 text-center">
            <p className="text-gray-700 mb-2">Already have an account?</p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="button"
              onClick={() => navigate('/login')}
              className="w-full py-3 bg-white text-pink-600 font-bold rounded-lg 
                       hover:bg-pink-50 transition-colors duration-300
                       border border-pink-200 hover:border-pink-300"
              disabled={isLoading}
            >
              Login Here
            </motion.button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default RegisterPage;