import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getUserInfo } from './api';

const getMembershipLabel = (membership) => {
  switch (membership) {
    case 0:
      return 'Free User';
    case 1:
      return 'Standard Member';
    case 2:
      return 'Pro Member';
    case 3:
      return 'Lifetime Member';
    default:
      return 'Unknown';
  }
};

const UserDropdown = ({ userData, showToast }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isUnsubscribing, setIsUnsubscribing] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenType');
    window.dispatchEvent(new Event('loginStateChange'));
    navigate('/login');
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    try {
      const token = localStorage.getItem('token');
      const tokenType = localStorage.getItem('tokenType');
      
      // Clear all related storage
      localStorage.removeItem('userInfo');
      localStorage.removeItem('token');
      localStorage.removeItem('tokenType');
      
      // Re-fetch user info
      const userInfo = await getUserInfo(token);
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      localStorage.setItem('token', token);
      localStorage.setItem('tokenType', tokenType);
      
      window.dispatchEvent(new Event('loginStateChange'));
    } catch (error) {
      console.error('Failed to refresh user info:', error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleUnsubscribe = async () => {
    setIsUnsubscribing(true);
    try {
      // Simulate a 2-second delay
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      // Update user data to show as free user
      const updatedUserData = {
        ...userData,
        membership: 0
      };
      // localStorage.setItem('userInfo', JSON.stringify(updatedUserData));
      // window.dispatchEvent(new Event('loginStateChange'));

      // Show success message
      showToast('Successfully unsubscribed from premium membership', 'success');
    } catch (error) {
      console.error('Failed to unsubscribe:', error);
    } finally {
      setIsUnsubscribing(false);
    }
  };

  // Only return null if there's no data
  if (!userData) return null;

  return (
    <div className="relative">
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => setIsOpen(!isOpen)}
        className="px-4 py-2 bg-pink-500 text-white font-bold rounded-lg 
                 hover:bg-pink-400 transition-colors duration-300
                 border-2 border-pink-400 hover:border-pink-300"
      >
        {userData.name || userData.email}
      </motion.button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="absolute right-0 mt-2 w-64 bg-white border-2 border-pink-300 
                     rounded-lg shadow-lg overflow-hidden z-50"
          >
            <div className="p-4">
              <div className="mb-4 border-b border-pink-200 pb-2">
                <p className="text-gray-700">Name: {userData.name || 'Not set'}</p>
                <p className="text-gray-700">Email: {userData.email}</p>
              </div>
              <div className="mb-4">
                <p className="text-gray-700 font-bold">
                  Membership: {getMembershipLabel(userData.membership)}
                </p>
              </div>
              <button
                onClick={handleRefresh}
                disabled={isRefreshing}
                className={`w-full py-2 mb-2 bg-pink-500 text-white font-bold rounded-lg 
                         hover:bg-pink-400 transition-colors duration-300
                         border-2 border-pink-400 hover:border-pink-300
                         disabled:opacity-50 disabled:cursor-not-allowed
                         flex items-center justify-center`}
              >
                {isRefreshing ? (
                  <>
                    <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></div>
                    Refreshing...
                  </>
                ) : (
                  'Refresh Status'
                )}
              </button>
              {userData.membership !== 0 && (
                <button
                  onClick={handleUnsubscribe}
                  disabled={isUnsubscribing}
                  className={`w-full py-2 mb-2 bg-red-500 text-white font-bold rounded-lg 
                           hover:bg-red-400 transition-colors duration-300
                           border-2 border-red-400 hover:border-red-300
                           disabled:opacity-50 disabled:cursor-not-allowed
                           flex items-center justify-center`}
                >
                  {isUnsubscribing ? (
                    <>
                      <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></div>
                      Unsubscribing...
                    </>
                  ) : (
                    'Cancel Subscription'
                  )}
                </button>
              )}
              <button
                onClick={handleLogout}
                className="w-full py-2 bg-red-500 text-white font-bold rounded-lg 
                         hover:bg-red-400 transition-colors duration-300"
              >
                Logout
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default UserDropdown; 